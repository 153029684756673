import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Measurement, SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { get, maxBy, minBy } from "lodash";
import { VrsErrorMsg } from "@veris-health/web-core";
import { SensorLabel } from "../components/Sensors/SensorLabel";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  BarDataSingleMeasurement,
  BloodPressureNormalRange,
  MioSensorMeasurement,
  SensorNormalRange,
  SensorsGraphView,
  selectBarSensorsData,
  selectFormattedSensorsData,
  selectMeasurementsStatus,
  selectMioSensors,
  selectReferenceLine,
} from "../measurementSlice";
import {
  CombinedSensorLabelProps,
  SensorValueYAxisRange,
  SensorsByDataSource,
} from "../components/shared/constants";
import { VrsPatientInfo } from "../../shared/interfaces";
import { CombinedGraph } from "../components/Sensors/CombinedGraph";
import CustomTooltip from "../context/TooltipContext";
import MioGraph from "../components/Sensors/MioGraph";
import { MeasurementGraph } from "../components/Sensors/MeasurementGraph";
import VrsGraphSelect from "../components/VrsGraphSelect";
import { MeasurementUnits } from "../../../constants";
import { getSensorsPerSource } from "../../../utils/helpers";

export interface CombinedGraphProps {
  isPreview?: boolean;
  fetchData?: () => void;
  currentPatient: VrsPatientInfo;
  sensorsGraphView: SensorsGraphView;
}

export interface Sensor {
  name: SensorEnum;
  mioValues?: MioSensorMeasurement[];
  implantValues?: (Measurement | null)[];
  externalValues?: BarDataSingleMeasurement[];
  label?: CombinedSensorLabelProps;
  dataKey: string;
  implantKey: string;
  normalRange:
    | {
        min: number | BloodPressureNormalRange;
        max: number | BloodPressureNormalRange;
      }
    | undefined;
  secondaryDataKey?: string;
  withMeasurementUnit?: string;
  type?: string;
}

export const CombinedGraphContainer = ({
  isPreview,
  fetchData,
  currentPatient,
  sensorsGraphView,
}: CombinedGraphProps): JSX.Element => {
  const referenceLine = useAppSelector(selectReferenceLine);
  const sensorDataStatus = useAppSelector(selectMeasurementsStatus);
  const sensorsData = useAppSelector((state) =>
    selectFormattedSensorsData(state, currentPatient.id),
  );
  const mioSensorsData = useAppSelector((state) => selectMioSensors(state, +currentPatient.id));
  const externalData = useAppSelector((state) => selectBarSensorsData(state, currentPatient.id));
  const patientSources = currentPatient?.dataSources;
  const combinedSensorsList = getSensorsPerSource(patientSources || [], SensorsByDataSource, "id");

  const getInitialType = (sensor: SensorEnum.Temp | SensorEnum.Bpm) => {
    if (
      mioSensorsData &&
      mioSensorsData[sensor]?.data.length > 0 &&
      !sensorsData[sensor]?.data.length
    ) {
      return "At-home-device";
    }
    if (
      sensorsData &&
      sensorsData[sensor]?.data.length > 0 &&
      (!mioSensorsData || !mioSensorsData[sensor]?.data.length)
    ) {
      return "Implant";
    }

    if (
      sensorsData[sensor]?.data.length > 0 &&
      (!mioSensorsData || mioSensorsData[sensor]?.data.length > 0)
    ) {
      return "Combined";
    }

    return "Combined";
  };

  const formattedSensors: { [key: string]: Sensor } = {
    temp: {
      name: SensorEnum.Temp,
      implantValues: sensorsData?.temp?.data || [],
      mioValues: mioSensorsData?.temp?.data || [],
      withMeasurementUnit: MeasurementUnits[SensorEnum.Temp],
      normalRange: sensorsData?.temp?.normalRange || mioSensorsData?.temp.normalRange,
      dataKey: "v",
      implantKey: "v",
      type: getInitialType(SensorEnum.Temp),
    },
    bpm: {
      name: SensorEnum.Bpm,
      implantValues: sensorsData?.bpm?.data || [],
      mioValues: mioSensorsData?.bpm?.data || [],
      withMeasurementUnit: MeasurementUnits[SensorEnum.Bpm],
      normalRange: sensorsData?.bpm?.normalRange || mioSensorsData?.bpm?.normalRange,
      dataKey: "v",
      implantKey: "v",
      type: getInitialType(SensorEnum.Bpm),
    },

    rpm: {
      name: SensorEnum.Rpm,
      implantValues: sensorsData?.rpm?.data || [],
      mioValues: undefined,
      normalRange: sensorsData?.rpm?.normalRange,
      withMeasurementUnit: MeasurementUnits[SensorEnum.Rpm],
      dataKey: "",
      implantKey: "v",
      type: "Implant",
    },

    oximeter: {
      name: SensorEnum.Oximeter,
      mioValues: mioSensorsData?.oximeter?.data || [],
      dataKey: "v",
      withMeasurementUnit: MeasurementUnits[SensorEnum.Oximeter],
      normalRange: mioSensorsData?.oximeter?.normalRange,
      implantKey: "",
      type: "At-home-device",
    },
    blood_pressure: {
      name: SensorEnum.BloodPressure,
      mioValues: mioSensorsData?.blood_pressure?.data || [],
      dataKey: "sys",
      secondaryDataKey: "dia",
      withMeasurementUnit: MeasurementUnits[SensorEnum.BloodPressure],
      normalRange: mioSensorsData?.blood_pressure?.normalRange,
      implantKey: "",
      type: "At-home-device",
    },
    weight: {
      name: SensorEnum.Weight,
      mioValues: mioSensorsData?.weight?.data || [],
      dataKey: "v",
      withMeasurementUnit: MeasurementUnits[SensorEnum.Weight],
      normalRange: mioSensorsData?.weight?.normalRange || { min: 0, max: 0 },
      implantKey: "",
      type: "At-home-device",
    },
    motion: {
      name: SensorEnum.Motion,
      implantValues: sensorsData?.motion?.data || [],
      mioValues: mioSensorsData?.motion?.data || [],
      externalValues: externalData?.motion?.data || [],
      withMeasurementUnit: MeasurementUnits[SensorEnum.Motion],
      normalRange:
        sensorsData?.motion?.normalRange ||
        mioSensorsData?.motion?.normalRange ||
        externalData?.motion.normalRange,
      dataKey: "sum",
      implantKey: "sum",
      type: "Combined",
    },
    ecg: {
      name: SensorEnum.Ecg,
      implantValues: sensorsData?.ecg?.data || [],
      dataKey: "",
      normalRange: sensorsData?.ecg?.normalRange,
      withMeasurementUnit: MeasurementUnits[SensorEnum.Ecg],
      implantKey: "v",
      type: "Implant",
    },
  };
  const matchedSensors: Sensor[] = Object.values(combinedSensorsList).map((value) => ({
    ...formattedSensors[value.id],
    label: combinedSensorsList.find(({ id }) => id === value.id),
  }));

  const getMaxMeasured = (max1: number, max2: number) => {
    if (max1 && max2) return Math.max(max1, max2);
    return max1 || max2;
  };

  const getMinMeasured = (min1: number, min2: number) => {
    if (min1 && min2) return Math.min(min1, min2);
    return min1 || min2;
  };

  function checkOutlayingValues(sensorName: SensorEnum): boolean {
    const sensorData = matchedSensors.find((sensor) => sensor.name === sensorName);

    if (sensorData) {
      const maxImplant = get(
        maxBy(sensorData.implantValues, (value) => get(value, sensorData.implantKey)),
        sensorData.implantKey,
      );
      const maxMio = get(
        maxBy(sensorData.mioValues, (value) => get(value, sensorData.dataKey)),
        sensorData.dataKey,
      );
      const minImplant = get(
        minBy(sensorData.implantValues, (value) => get(value, sensorData.implantKey)),
        sensorData.implantKey,
      );
      const minMio = get(
        minBy(sensorData.mioValues, (value) =>
          get(value, sensorData.secondaryDataKey ?? sensorData.dataKey),
        ),
        sensorData.secondaryDataKey ?? sensorData.dataKey,
      );
      const max = getMaxMeasured(maxImplant, maxMio);
      const min = getMinMeasured(minImplant, minMio);

      if (
        max &&
        min &&
        (max > +SensorValueYAxisRange[sensorData.name].max ||
          min < SensorValueYAxisRange[sensorData.name].min)
      )
        return true;
    }
    return false;
  }

  const initialState = matchedSensors.reduce(
    (stateObj, sensor) => {
      stateObj[sensor.name] = sensor.type as string;
      return stateObj;
    },
    {
      blood_pressure: "",
      bpm: "",
      ecg: "",
      motion: "",
      oximeter: "",
      rpm: "",
      temp: "",
      weight: "",
      sleep: "",
    },
  );
  const options = ["At-home-device", "Implant", "Combined"];
  const [activeGraph, setActiveGraph] = useState(initialState);

  useEffect(() => {
    if (sensorDataStatus === "idle") setActiveGraph(initialState);
  }, [sensorDataStatus]);

  const handleSwitch = (value: string, sensor: string) => {
    setActiveGraph({ ...activeGraph, [sensor as SensorEnum]: value });
  };

  return (
    <CustomTooltip>
      <Box display="flex" px={0} py={1}>
        {!isPreview && (
          <Box sx={{ overflow: "initial" }}>
            {combinedSensorsList.map((sensor, index) => (
              <React.Fragment key={sensor.title}>
                <SensorLabel
                  {...sensor}
                  isFirst={index === 0}
                  isLast={index === combinedSensorsList.length - 1}
                  outlayingValues={checkOutlayingValues(sensor.id)}
                />
              </React.Fragment>
            ))}
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
          }}
        >
          {sensorDataStatus === "failed" && <VrsErrorMsg onClick={() => fetchData?.()} />}
          {sensorDataStatus === "loading" && (
            <Box
              display="flex"
              justifyContent="center"
              height={matchedSensors.length * 110}
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {sensorDataStatus === "idle" &&
            matchedSensors.map(
              ({
                withMeasurementUnit,
                implantValues,
                mioValues,
                externalValues,
                name,
                label,
                normalRange,
                dataKey,
                implantKey,
                secondaryDataKey,
              }) =>
                normalRange && (
                  <React.Fragment key={name}>
                    <Box
                      display="flex"
                      justifyContent="space-evenly"
                      sx={{
                        position: "relative",
                        borderBottom: (theme) =>
                          !isPreview && name === "ecg"
                            ? `2px solid ${theme.veris.colors.neutrals["grey-2"]}`
                            : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                        borderTop: (theme) =>
                          !isPreview && name === "temp"
                            ? `2px solid ${theme.veris.colors.neutrals["grey-2"]}`
                            : `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,

                        height: !isPreview ? "110px" : "unset",
                      }}
                    >
                      <Box flex={1} width={0}>
                        {isPreview && (
                          <Box>
                            {" "}
                            <SensorLabel
                              {...label}
                              isPreview={isPreview}
                              outlayingValues={checkOutlayingValues(name)}
                            />{" "}
                            {mioValues &&
                              mioValues.length > 0 &&
                              implantValues &&
                              implantValues.length > 0 && (
                                <VrsGraphSelect
                                  handleChange={(e) => handleSwitch(e.target.value, name)}
                                  options={options}
                                  value={activeGraph[name]}
                                  isPreview={isPreview}
                                />
                              )}
                          </Box>
                        )}
                        {activeGraph[name] === "At-home-device" && (
                          <MioGraph
                            sensorName={name}
                            data={mioValues || []}
                            dataKey={dataKey}
                            withMeasurementUnit={withMeasurementUnit}
                            isPreview={isPreview}
                            referenceLine={referenceLine}
                            secondaryDataKey={secondaryDataKey}
                            normalRange={normalRange}
                            sensorsGraphView={sensorsGraphView}
                          />
                        )}

                        {activeGraph[name] === "Combined" && (
                          <CombinedGraph
                            sensorName={name}
                            referenceLine={referenceLine}
                            key={name}
                            dataKey={dataKey}
                            implantKey={implantKey}
                            secondaryDataKey={secondaryDataKey}
                            isPreview={isPreview}
                            implantValues={implantValues || []}
                            mioValues={mioValues || []}
                            withMeasurementUnit={withMeasurementUnit}
                            normalRange={normalRange}
                            externalValues={externalValues || []}
                            sensorsGraphView={sensorsGraphView}
                          />
                        )}
                        {activeGraph[name] === "Implant" && (
                          <MeasurementGraph
                            sensorName={name}
                            referenceLine={referenceLine}
                            key={name}
                            isPreview={isPreview}
                            sensorValues={implantValues || []}
                            withMeasurementUnit={withMeasurementUnit}
                            syncId="sensorCharts"
                            normalRange={normalRange as SensorNormalRange}
                            sensorsGraphView={sensorsGraphView}
                          />
                        )}
                      </Box>
                      {mioValues &&
                        mioValues.length > 0 &&
                        implantValues &&
                        implantValues.length > 0 &&
                        !isPreview && (
                          <VrsGraphSelect
                            handleChange={(e) => handleSwitch(e.target.value, name)}
                            options={options}
                            value={activeGraph[name]}
                          />
                        )}
                    </Box>
                  </React.Fragment>
                ),
            )}
        </Box>
      </Box>
    </CustomTooltip>
  );
};
