import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { ExportSection, ExportSectionProps } from "../ExportSection";
import { VrsPatientInfo } from "../../../../shared/interfaces";
import { MioGraphContainer } from "../../../../PatientDetailsMeasurements/containers/MioGraphContainer";
import { DateHeader } from "./DateHeader";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import {
  SensorsGraphView,
  selectDateFrom,
  selectDateTo,
  selectSensorsGraphView,
} from "../../../../PatientDetailsMeasurements/measurementSlice";
import { PatientDetailsMeasurementContainer } from "../../../../PatientDetailsMeasurements/PatientDetailsMeasurementContainer";
import { CombinedGraphContainer } from "../../../../PatientDetailsMeasurements/containers/CombinedGraphContainer";
import { useCurrentPatient } from "../../../../../hooks/useCurrentPatient";

interface PhysiologicDataGraphsProps extends ExportSectionProps {
  currentPatient: VrsPatientInfo;
  fetchData: () => void;
}

export function PhysiologicDataGraphs({
  title,
  include,
  onInclude,
  currentPatient,
  fetchData,
  iconName,
}: PhysiologicDataGraphsProps): JSX.Element {
  const startDate = useAppSelector(selectDateFrom);
  const endDate = useAppSelector(selectDateTo);
  const sensorsGraphView = useAppSelector(selectSensorsGraphView);
  const [view, setView] = useState<SensorsGraphView>("weekly");
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  const calculateWidth = (): string => {
    if (isSmallScreen) return "1000px";
    if (isMediumScreen) return "1200px";
    return "1400px";
  };

  useEffect(() => {
    const diff = dayjs(endDate).diff(startDate, "days");

    switch (true) {
      case diff < 1:
        setView("daily");
        break;
      case diff === 3:
        setView("3-days");
        break;
      case diff > 4 && diff <= 10:
        setView("weekly");
        break;
      case diff > 11:
        setView("monthly");
        break;
      default:
        setView("weekly");
        break;
    }
  }, [startDate, endDate]);
  return (
    <ExportSection title={title} iconName={iconName} include={include} onInclude={onInclude}>
      <Box
        sx={{ marginY: (theme) => theme.spacing(3), width: calculateWidth(), marginX: "auto" }}
        className="break-before"
      >
        <DateHeader startDate={startDate} endDate={endDate} view={view} />
        <CombinedGraphContainer
          currentPatient={currentPatient}
          sensorsGraphView={sensorsGraphView}
        />
      </Box>
    </ExportSection>
  );
}
